<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Gyfy/banner.jpg" alt="" width="100%" />
    </div>
    <!-- 关于发业 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>关于发业</span>
    </div>
    <div>
        <img src="../img/Gyfy/tu01.jpg" alt="" width="100%" />
        <div class="textbox">
                <span></span>   发业财税总部设立在广州珠江新城，主营工商注册、代理记账、知识产权、各种企业资质办理、企业财税综合服务等“企业一站式服务”。<br><br>

      <span></span> 发业自成立以来，成功服务了来自全国各地区的超过10万家客户，为众多企业及个人提供专业而标准的企业服务，助力客户实现梦想。发业经过10多年的品牌积淀，在广大客户中建立了超卓信誉，并铸就出稳居中国企业服务行业前列的旗舰品牌。<br><br>

      <span></span> 随着税务环境的不断规范化数据化。当前人工智能时代，发业财税致力打造专业高效的财税处理方案，自行研发财税智能管理系统：融合税务、发票管理、智能AI识别票据技术等平台实现数据智能采集、高效管理进销存、风险主动评估把控、财税漏洞追踪解决等重要财税运用场景，让广大企业可以更轻松地提高财税质量，降低经营成本以及涉税风险。<br><br>
        </div>
    </div>
    <!-- 关于发业地址 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>关于发业</span>
    </div>
    <div class="tubox">
        <img src="../img/Gyfy/tu02.jpg" alt="" width="340" />
    </div>
    <div class="lianxibox">
        <span>联系方式</span>
        电话：020-29878999 <br>
手机：15220031989<br>
QQ：3560475114<br>
地址：广州市天河区珠江新城华穗路263号双城国际<br>
东座23层（地铁3号/5号线珠江新城A2出口前行50米）<br>
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from '@/components/Bottom.vue';
import Fixd from './Fixd.vue';
import Header from './header.vue';
export default {
    components:{ Bottom, Fixd, Header },
  data() {
    return {
 
    };
  },

  methods: {
     online_advice(){
      this.$GM.as()
    },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.lianxibox{
   padding: 10px 20px;
    line-height: 20px;
    background-color: #fff; 
    span{
        font-size: 24px;
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
    }
}
.textbox{
    padding: 10px 20px;
    line-height: 20px;
    background-color: #fff;
    span{
        display: inline-block;
        width: 25px;
        
    }
}
.tubox{
    padding: 10px 20px;
    background-color: #fff;
    background-size: 100%;
    img{
        width: 100%;
        height: 100%;
    }
}

.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}

</style>