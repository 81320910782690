<template>
  <div>
    <van-list
      :finished="finished"
      finished-text="没有更多了"
    >
      <van-cell v-for="(item,index) in searchTipsList" :key="index" :title="item" />
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finished: true,
    };
  },
  props:["searchTipsList"],
  methods: {
 
  },
};
</script>

<style>
</style>