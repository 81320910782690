<template>
    <div>
        <Header />
        <!-- banner -->
        <div @click="online_advice()">
            <img src="../img/Lwxkz/banner.jpg" alt="" width="100%" />
        </div>
        <!-- 什么是劳务派遣经营许可证 -->
        <div class="cleadox"></div>
        <div class="titlebox">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>什么是劳务派遣经营许可证</span>
        </div>
        <div class="lcbigbox">
            <div class="showbox">
                <div class="textbox">
                    劳务派遣是指由劳务派遣机构与派遣劳工订立劳动合同，把劳动者派向其他用工单位，再由其用工单位向派遣机构支付一笔服务费用的一种用工形式。 
                </div>
                <div class="lcbox">
                    <img src="../img/Lwxkz/tu01.jpg" alt="" width="310">
                </div>
                 <!-- 点击拨打电话 -->
                <div class="call" @click="Botell" style="margin: 0 auto; text-align: center;">
                    <img src="../img/Lwxkz/call.png" alt="" width="310" style="margin-left: -8px;">
                </div> 
            </div>
            
        </div>
        <!-- 劳务派遣经营许可证实用企业 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>劳务派遣经营许可证实用企业</span>
        </div>
        <div class="shipingbox">
            <div class="lcbox">
                <ul>
                    <li style="text-align: center" v-for="item in TjLists" :key="item.id">
                    <img :src="item.image" alt="" width="350">
                    </li>
                </ul>
            </div>
        </div>
        <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
            <img src="../assets/call.png" alt="" width="310">
        </div>
        <div class="tongdaobigbox">
            <div style="" class="tdnbox">
            <img src="../img/Dlxkz/bjtu.png" alt="" width="350">
            <div class="tdnbox-from">
                <div style="font-size: 18px;line-height: 25px;margin-bottom: 40px;color: red;">
                    劳务派遣许可办理
                </div>
                <van-form @submit="onSubmit">
                <van-field
                    v-model="username"
                    name="公司名称"
                    label="公司名称"
                    placeholder="公司名称"
                    :rules="[{ required: true, message: '请输入公司名称' }]"
                />
                <van-field
                    v-model="password"
                    name="电话号码"
                    label="电话号码"
                    placeholder="电话号码"
                    :rules="[{ required: true, message: '清输入电话号码' }]"
                />
                <div style="margin: 25px;">
                    <van-button type="danger" native-type="submit" style="width:258px;height:46px;">立即获取本月优惠</van-button>
                </div>
                </van-form>
            </div>
            </div>  
        </div>
        <!-- 劳务派遣经营许可证办理流程 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>劳务派遣经营许可证办理流程</span>
        </div>
        <div class="lcbigbox">
            <div class="lcbox">
                <img src="../img/Lwxkz/tu06.jpg" alt="" width="350" />
            </div>
        </div>
        <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
            <img src="../assets/call.png" alt="" width="310">
        </div>
        <!-- 办理许可证可能遇到的问题 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>办理许可证可能遇到的问题</span>
        </div>
        <div class="lcbigbox">
            <div style="margin:0 auto; position: relative;" @click="Botell">
                <div style="text-align: center">
                    <img src="../img/Lwxkz/tu07.jpg" alt="" width="350">
                </div>
                <div style=" position:absolute;top: 23px;left: 18%;color:#282828;">
                    劳务派遣经营许可证办理需要具备什么条件？
                </div>
                <div style=" position:absolute;bottom: 23px;left: 18%;color:#fa2f2f;">
                    020-29878999在线咨询
                </div>
            </div>
            <div style="margin:0 auto; position: relative;" @click="Botell">
                <div style="text-align: center">
                    <img src="../img/Lwxkz/tu07.jpg" alt="" width="350">
                </div>
                <div style=" position:absolute;top: 23px;left: 18%;color:#282828;">
                    从事劳务派遣业务不办理许可有什么处罚？
                </div>
                <div style=" position:absolute;bottom: 23px;left: 18%;color:#fa2f2f;">
                    020-29878999在线咨询
                </div>
            </div>
        </div >
        <!-- 11年代办服务 大机构有实力更有效率 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>11年代办服务 大机构有实力更有效率</span>
        </div>
        <div class="TongDaoListsbox">
            <van-grid :border="false" :column-num="1">
                <van-grid-item  v-for="item in TongDaoLists" :key="item.id" class="TongDaoLists">
                <div class="Tdboxmallbox">
                    <div>
                    <img :src="item.image" alt="" width="68">
                    </div>
                    <div >
                    <em>{{item.name}}</em>
                    <span>{{item.text}}</span>
                    </div>
                </div> 
                </van-grid-item> 
            </van-grid>
        </div>
        <Fixd />
        <Bottom />
    </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import { add_xkz } from '@/request/api';
import Fixd from "./Fixd.vue";
import Lwxkz from "@/views/Lwxkz.vue";
import Header from "./header.vue";
export default {
    components: { Bottom, Fixd, Lwxkz, Header },
    data() {
        return {
            username: "",
            password: "",
            value: "",
            showPicker: false,
            TjLists:[
                {
                id: 0,
                image: require("@/img/Lwxkz/tu02.jpg"),
                },
                {
                id: 1,
                image: require("@/img/Lwxkz/tu03.jpg"),
                },
                {
                id: 2,
                image: require("@/img/Lwxkz/tu04.jpg"),
                },
                {
                id: 3,
                image: require("@/img/Lwxkz/tu05.jpg"),
                },
            ],
            TongDaoLists:[
                {
                    id:0,
                    image: require("@/img/Lwxkz/icon01.jpg"),
                    name:"11年服务经验",
                    text:'服务超1W+客户'
                },
                {
                    id:1,
                    image: require("@/img/Lwxkz/icon02.jpg"),
                    name:'经验丰富，不走弯路',
                    text:'办理下证快'
                },
                {
                    id:2,
                    image: require("@/img/Lwxkz/icon03.png"),
                    name:'1V1专人服务',
                    text:'24小时跟踪办理'
                },
                {
                    id:3,
                    image: require("@/img/Lwxkz/icon04.png"),
                    name:'透明报价收费',
                    text:'办理不成功则退款' 
                }

            ],
        }
    },
    methods: {
        onSubmit(values) {
        console.log("submit", values);
        add_xkz({"name":values.公司名称,"phono":values.电话号码}).then((response)=>{
            alert(response.message)
        })
        },
        onConfirm(value) {
        this.value = value;
        this.showPicker = false;
        },
        Botell(phoneNumber){
        this.$GM.callPhone(phoneNumber)
        },
    },
}
</script>
<style lang="scss"  scoped>
.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
    
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .showbox{
    width: 78%;
    padding: 20px;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    .textbox {
        color: #565656;
        line-height: 25px;
        text-indent:3cap; 
    }
  }
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
  .dltextbox {
    position: absolute;
    color: #fff;
    line-height: 25px;
    padding: 20px 55px;
    text-indent:3cap;
    left: 0;
    width: 185px;
  }
}
.fanweibox,
.shipingbox,
.cailiaobox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
    text-align: center;
    line-height: 20px;
  }
}
.tongdaobigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  margin: 0 auto;
  .tdnbox {
    text-align: center;
    position: relative;
    .tdnbox-from {
      position: absolute;
      top: 60px;
      left: 11%;
      .van-cell {
        background-color: transparent;
      }
    }
  }
}
.TongDaoListsbox{
  padding-bottom: 30px;
  padding-top: 20px;
  background-color: #fff;
}
.TongDaoLists{
  .Tdboxmallbox {
    width: 310px;
    display: flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
  em{
    font-style: normal;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #1f1f1f;
    font-weight: bold;
  }
  span{
     font-style: normal;
     font-style: normal;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    color: #5d5d5d;
  }
  }
  
}
</style>