<template>
    <div class="Agreementbox">
        <div>
            <van-nav-bar title="用户协议" left-text="返回" left-arrow @click-left="onClickLeft" />
        </div>
        <Agreement />
    </div>
</template>
<script>
import Agreement from '@/components/agreement.vue';
export default {
    data() {
        return {};
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        }
    },
    components: { Agreement }
};
</script>
<style lang="scss" scoped>
.Agreementbox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    overflow-y: scroll;
}
</style>