<template>
    <div class="Lwxkz">
      <LWXKZ />
    </div>
  </template>
  <script>
  import LWXKZ from "@/components/LWXKZ.vue";
  export default {
    components: { LWXKZ },
    data() {
      return {};
    },
  
    methods: {},
  };
  </script>
  <style lang="scss" scoped>
  .Lwxkz {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #efefef;
    overflow-y: scroll;
  }
  </style>