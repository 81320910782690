<template>
    <div>
        <Header />
        <!-- banner -->
        <div @click="online_advice()">
            <img src="../img/Wsxkz/banner.jpg" alt="" width="100%" />
        </div>
        <!-- 什么是卫生许可证？ -->
        <div class="cleadox"></div>
        <div class="titlebox">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>什么是卫生许可证？</span>
        </div>
        <div class="lcbigbox">
            <div class="box_sm">
                <div style="text-align: center">
                    <img src="../img/Wsxkz/tu01.jpg" alt=""  width="310" style="margin-left: -10px;">
                </div>
                <div class="dltextbox">
                    公共场所卫生许可证是为创造良好的公共场所卫生条件，预防疾病，保障人体健康，公共场所的卫生标准和要求，由县以上卫生行政部门签发许可证
                </div>
                <!-- <div class="lcbox_box">
                    <div class="dltextbox">
                        医疗器械经营许可证是医疗器械经营企业必须具备的证件，医疗器械经营许可证现为后置审批，工商行政管理部门发给营业执照后申请审批。
                    </div>
                    <div>
                        <img src="../img/Jxxkz/tu01.jpg" alt="" width="120">
                    </div>
                </div> -->
                <div class="call" @click="Botell">
                    <img src="../img/Wsxkz/call.jpg" alt="" width="310"  style="margin-left: -8px; margin-top:20px;">
                </div>
            </div>
        </div>
        <!-- 哪些业务需要办理医疗器械经营许可证？ -->
        <!-- <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>哪些业务需要办理医疗器械经营许可证？</span>
        </div>
        <div>
            <van-grid :border="false" :column-num="2">
                <van-grid-item  v-for="item in whoLists" :key="item.id" >
                    <div class="BDbox">
                        <div>
                            <img :src="item.image" alt="" width="50">
                        </div>
                        <div class="BDboxtst">
                           {{item.test}}
                        </div>
                    </div> 
                </van-grid-item>
            </van-grid>
        </div> -->
        <!-- 点击拨打电话 -->
        <!-- <div class="call" @click="Botell">
            <img src="../assets/call.png" alt="" width="310">
        </div> -->
        <!-- 哪些场所要办理卫生许可证？ -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>哪些场所要办理卫生许可证？</span>
        </div>
        <div class="shipingbox">
            <div class="lcbox">
                <ul>
                    <li style="text-align: center" v-for="item in TjLists" :key="item.id">
                    <img :src="item.image" alt="" width="350">
                    </li>
                </ul>
            </div>
        </div>
        <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
            <img src="../assets/call.png" alt="" width="310">
        </div>
        <div class="tongdaobigbox">
            <div style="" class="tdnbox">
            <img src="../img/Dlxkz/bjtu.png" alt="" width="350">
            <div class="tdnbox-from">
                <div style="font-size: 18px;line-height: 25px;margin-bottom: 40px;color: red;">
                    卫生许可代办
                </div>
                <van-form @submit="onSubmit">
                <van-field
                    v-model="username"
                    name="公司名称"
                    label="公司名称"
                    placeholder="公司名称"
                    :rules="[{ required: true, message: '请输入公司名称' }]"
                />
                <van-field
                    v-model="password"
                    name="电话号码"
                    label="电话号码"
                    placeholder="电话号码"
                    :rules="[{ required: true, message: '清输入电话号码' }]"
                />
                <div style="margin: 25px;">
                    <van-button type="danger" native-type="submit" style="width:258px;height:46px;">立即获取本月优惠</van-button>
                </div>
                </van-form>
            </div>
            </div>  
        </div>
        <!-- 办理卫生许可证流程 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>办理卫生许可证流程</span>
        </div>
        <div class="lcbigbox">
            <div class="lcbox" style="text-align: center;">
                <img src="../img/Wsxkz/tu08.jpg" alt="" width="350" />
            </div>
        </div>
        <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
            <img src="../assets/call.png" alt="" width="310">
        </div>
        <!-- 办理卫生许可证常遇见的问题 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>办理卫生许可证常遇见的问题</span>
        </div>
        <div class="lcbigbox">
            <div style="margin:0 auto; position: relative;" @click="Botell">
                <div style="text-align: center">
                    <img src="../img/Lwxkz/tu07.jpg" alt="" width="350">
                </div>
                <div style=" position:absolute;top: 23px;left: 18%;color:#282828;">
                    卫生可证有效期多久？ 
                </div>
                <div style=" position:absolute;bottom: 23px;left: 18%;color:#fa2f2f;">
                    020-29878999在线咨询
                </div>
            </div>
            <div style="margin:0 auto; position: relative;" @click="Botell">
                <div style="text-align: center">
                    <img src="../img/Lwxkz/tu07.jpg" alt="" width="350">
                </div>
                <div style=" position:absolute;top: 23px;left: 18%;color:#282828;">
                    公共场所卫生许可要年审吗？
                </div>
                <div style=" position:absolute;bottom: 23px;left: 18%;color:#fa2f2f;">
                    020-29878999在线咨询
                </div>
            </div>
            <div style="margin:0 auto; position: relative;" @click="Botell">
                <div style="text-align: center">
                    <img src="../img/Lwxkz/tu07.jpg" alt="" width="350">
                </div>
                <div style=" position:absolute;top: 23px;left: 18%;color:#282828;">
                    多久能办好卫生许可证？ 
                </div>
                <div style=" position:absolute;bottom: 23px;left: 18%;color:#fa2f2f;">
                    020-29878999在线咨询
                </div>
            </div>
            <div style="margin:0 auto; position: relative;" @click="Botell">
                <div style="text-align: center">
                    <img src="../img/Lwxkz/tu07.jpg" alt="" width="350">
                </div>
                <div style=" position:absolute;top: 23px;left: 18%;color:#282828;">
                    卫生许可证丢失了怎么办？
                </div>
                <div style=" position:absolute;bottom: 23px;left: 18%;color:#fa2f2f;">
                    020-29878999在线咨询
                </div>
            </div>
        </div >
        <!-- 11年代办服务 大机构有实力更有效率 -->
        <div class="titlebox" style="margin-top: 10px">
            <i>
                <img src="../img/Gsbg/lr.jpg" alt="" />
            </i>
            <span>11年代办服务 大机构有实力更有效率</span>
        </div>
        <div class="TongDaoListsbox">
            <van-grid :border="false" :column-num="1">
                <van-grid-item  v-for="item in TongDaoLists" :key="item.id" class="TongDaoLists">
                <div class="Tdboxmallbox">
                    <div>
                    <img :src="item.image" alt="" width="68">
                    </div>
                    <div >
                    <em>{{item.name}}</em>
                    <span>{{item.text}}</span>
                    </div>
                </div> 
                </van-grid-item> 
            </van-grid>
        </div>
        <Fixd />
        <Bottom />
    </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import { add_xkz } from '@/request/api';
import Fixd from "./Fixd.vue";
import Wsxkz from "@/views/Wsxkz.vue";
import Header from "./header.vue";
export default {
    components: { Bottom, Fixd, Wsxkz, Header },
    data() {
        return {
            username: "",
            password: "",
            value: "",
            showPicker: false,
            whoLists:[
                {
                    id:0,
                    image:require("@/img/Jxxkz/icon01.jpg"),
                    test:"绷带"
                },
                {
                    id:1,
                    image:require("@/img/Jxxkz/icon02.jpg"),
                    test:"纱布"
                },
                {
                    id:2,
                    image:require("@/img/Jxxkz/icon03.jpg"),
                    test:"创可贴"
                },
                {
                    id:3,
                    image:require("@/img/Jxxkz/icon04.jpg"),
                    test:"医用口罩"
                },
                {
                    id:4,
                    image:require("@/img/Jxxkz/icon05.jpg"),
                    test:"血压计"
                },
                {
                    id:5,
                    image:require("@/img/Jxxkz/icon06.jpg"),
                    test:"体温计"
                },
                {
                    id:6,
                    image:require("@/img/Jxxkz/icon07.jpg"),
                    test:"防护服"
                },
                {
                    id:7,
                    image:require("@/img/Jxxkz/icon08.jpg"),
                    test:"显微镜"
                },
                {
                    id:8,
                    image:require("@/img/Jxxkz/icon09.jpg"),
                    test:"助听器"
                },
                {
                    id:9,
                    image:require("@/img/Jxxkz/icon10.jpg"),
                    test:"雾化器"
                }
            ],
            TjLists:[
                {
                id: 0,
                image: require("@/img/Wsxkz/tu02.jpg"),
                },
                {
                id: 1,
                image: require("@/img/Wsxkz/tu03.jpg"),
                },
                {
                id: 2,
                image: require("@/img/Wsxkz/tu04.jpg"),
                },
                {
                id: 3,
                image: require("@/img/Wsxkz/tu05.jpg"),
                },
                {
                id: 4,
                image: require("@/img/Wsxkz/tu06.jpg"),
                },
                {
                id: 5,
                image: require("@/img/Wsxkz/tu07.jpg"),
                }
            ],
            TongDaoLists:[
                {
                    id:0,
                    image: require("@/img/Lwxkz/icon01.jpg"),
                    name:"11年服务经验",
                    text:'服务超1W+客户'
                },
                {
                    id:1,
                    image: require("@/img/Lwxkz/icon02.jpg"),
                    name:'经验丰富，不走弯路',
                    text:'办理下证快'
                },
                {
                    id:2,
                    image: require("@/img/Lwxkz/icon03.png"),
                    name:'1V1专人服务',
                    text:'24小时跟踪办理'
                },
                {
                    id:3,
                    image: require("@/img/Lwxkz/icon04.png"),
                    name:'透明报价收费',
                    text:'办理不成功则退款' 
                }

            ],
        }
    },
    methods: {
        onSubmit(values) {
        console.log("submit", values);
        add_xkz({"name":values.公司名称,"phono":values.电话号码}).then((response)=>{
            alert(response.message)
        })
        },
        onConfirm(value) {
        this.value = value;
        this.showPicker = false;
        },
        Botell(phoneNumber){
        this.$GM.callPhone(phoneNumber)
        },
    },
}
</script>
<style lang="scss"  scoped>
.BDbox{
    width: 86%;
    padding: 4px 10px;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background-color: #d92119;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .BDboxtst{
        width: 50%;
        font-size: 18px;
        text-align: center;
        color: #fff;
        padding-right: 20px;
    }
}
.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
    
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .showbox{
    width: 78%;
    padding: 20px;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    .textbox {
        color: #565656;
        line-height: 25px;
        text-indent:3cap; 
    }
  }
  .box_sm{
    width: 78%;
    padding: 20px;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background-color: #fff;
    .lcbox_box {
    display: flex;
    justify-content: space-around;
  }
  .dltextbox {
    color: #565656;
    line-height: 25px;
    text-indent:3cap;

  }
  }
 
}
.fanweibox,
.shipingbox,
.cailiaobox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
    text-align: center;
    line-height: 20px;
  }
}
.tongdaobigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  margin: 0 auto;
  .tdnbox {
    text-align: center;
    position: relative;
    .tdnbox-from {
      position: absolute;
      top: 60px;
      left: 11%;
      .van-cell {
        background-color: transparent;
      }
    }
  }
}
.TongDaoListsbox{
  padding-bottom: 30px;
  padding-top: 20px;
  background-color: #fff;
}
.TongDaoLists{
  .Tdboxmallbox {
    width: 310px;
    display: flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
  em{
    font-style: normal;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #1f1f1f;
    font-weight: bold;
  }
  span{
     font-style: normal;
     font-style: normal;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    color: #5d5d5d;
  }
  }
  
}
</style>