import axios from "axios"
if(window.location.host=='http://m.ji-zhang.com'){
   var url="https://ji-zhang.com/"
}else{
   var url="https://gzfaye.com/"
}
// axios配置
const instance = axios.create({
    baseURL:url, //这是请求的接口
    // baseURL:'http:192.168.2.88:8011/', //这是请求的接口
    timeout:5000 //超过5秒没有响应就作废
});

//添加请求拦截器
instance.interceptors.request.use(config=>{

    return config

},err=>{
    return Promise.reject(err)
});
// 添加响应拦截器
instance.interceptors.response.use(res=>{
   
    return res.data
},err=>{
    return Promise.reject(err)
});

export default instance