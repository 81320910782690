<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Jkxkz/banner.jpg" alt="" width="100%" />
    </div>
    <!-- 什么是进出口权? -->
    <div class="cleadox"></div>
    <div class="titlebox">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>什么是进出口权?</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox" style="position: relative;">
        <img src="../img/Jkxkz/tu01.jpg" alt="" width="310">
        <div class="dltextbox">
          进出口权全称是指自主经营进出口经营权。只有拥有进出口权的企业，才可依法自主地从事进出口业务。
        </div>
      </div>
      
    </div>
    <!-- 办理进出口权的好处 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>办理进出口权的好处</span>
    </div>
    <div class="shipingbox">
      <div class="lcbox">
          <ul>
            <li style="text-align: center" v-for="item in TjLists" :key="id">
              <img :src="item.image" alt="" width="310">
            </li>
          </ul>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <div class="tongdaobigbox">
      <div style="" class="tdnbox">
          <img src="../img/Dlxkz/bjtu.png" alt="" width="310">
          <div class="tdnbox-from">
            <div style="font-size: 18px;line-height: 25px;margin-bottom: 40px;color: red;">
              进出口权在线办理
            </div>
            <van-form @submit="onSubmit">
              <van-field
                v-model="username"
                name="公司名称"
                label="公司名称"
                placeholder="公司名称"
                :rules="[{ required: true, message: '请输入公司名称' }]"
              />
              <van-field
                v-model="password"
                name="电话号码"
                label="电话号码"
                placeholder="电话号码"
                :rules="[{ required: true, message: '清输入电话号码' }]"
              />
              <div style="margin: 25px;">
                <van-button type="danger" native-type="submit" style="width:258px;height:46px;">立即获取本月优惠</van-button>
              </div>
            </van-form>
          </div>
      </div>
    </div>
    <!-- 进出口适用企业 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>进出口适用企业</span>
    </div>
    <div class="TongDaoListsbox">
      <van-grid :border="false" :column-num="2">
        <van-grid-item  v-for="item in TongDaoLists" :key="item.id" class="TongDaoLists">
          <div class="Tdboxmallbox">
            <img :src="item.image" alt="" width="68">
            <em>{{item.name}}</em>
          </div> 
        </van-grid-item> 
      </van-grid>
    </div>
    <!-- 进出口权申请流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>进出口权申请流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Jkxkz/tu06.jpg" alt="" width="310" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 办理进出口权，我们的优势 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>办理进出口权，我们的优势</span>
    </div>
    <div class="TongDaoListsbox">
      <van-grid :border="false" :column-num="1">
        <van-grid-item  v-for="item in TongDaoLists1" :key="item.id" class="TongDaoLists1">
          <div class="Tdboxmallbox1">
            <div>
              <img :src="item.image" alt="" width="68">
            </div>
            <div style="margin-left: 20px;">
              <em>{{item.name}}</em>
              <span>{{item.text}}</span>
            </div>
          </div> 
        </van-grid-item> 
      </van-grid>
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import { add_xkz } from '@/request/api';
import Fixd from "./Fixd.vue";
import Xkz from "@/views/Xkz.vue";
import Header from "./header.vue";
export default {
  components: { Bottom, Fixd, Xkz, Header },
  data() {
    return {
      username: "",
      password: "",
      value: "",
      columns: ["杭州", "宁波", "温州", "嘉兴", "湖州"],
      showPicker: false,
      Wentxtlists: [
        {
          id: 0,
          title: "道路运输经营许可证有效期多久？",
          text: "道路运输经营许可证的有效期为4年，但部分省市根据本地实际情况，具体可到当地运管机构咨询。",
        },
        {
          id: 1,
          title: "办理道路运输许可证对驾驶员要求有哪些？",
          text: "有符合规定条件的驾驶人员。具体条件包括：已取得相应机动车驾驶证;年龄不超过60周岁，并取得相关运输从业资格证。",
        },
      ],
      TjLists:[
        {
          id: 0,
          image: require("@/img/Jkxkz/tu02.jpg"),
        },
        {
          id: 1,
          image: require("@/img/Jkxkz/tu03.jpg"),
        },
        {
          id: 2,
          image: require("@/img/Jkxkz/tu04.jpg"),
        },
        {
          id: 3,
          image: require("@/img/Jkxkz/tu05.jpg"),
        }
      ],
      CLGridLists: [
        {
          id: 0,
          image: require("@/img/Xkz/icon01.png"),
          name: "国际贸易公司",
        },
        {
          id: 1,
          image: require("@/img/Xkz/icon02.png"),
          name: "报关公司",
        },
        {
          id: 2,
          image: require("@/img/Xkz/icon03.png"),
          name: "物流公司",
        },
        {
          id: 3,
          image: require("@/img/Xkz/icon04.png"),
          name: "货代公司",
        },
        {
          id: 4,
          image: require("@/img/Xkz/icon05.png"),
          name: "供应链公司",
        },
        {
          id: 5,
          image: require("@/img/Xkz/icon06.png"),
          name: "供应链公司",
        },
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Gsbg/icon01.png"),
          title: "专业团队",
          text: "专业团队办理，专业人做专业事，效率高",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/icon02.png"),
          title: "风险分析",
          text: "公司起名有大数据分析，公司字号经过我们研发的大数据分析，系统过滤风险，降低侵权风险",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/icon03.png"),
          title: "一站式服务",
          text: "一站式全面企业服务支持，注册公司、商标申请保护、版权登记保护、代理记账、发票托管等业务全面覆盖企业运用基本需求",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/icon04.png"),
          title: "售后保障",
          text: "售后有保障，系统监管办理进度、管理员负责管理、专员负责办理、办理进度可追踪，进度有保障。让办理变得简单高效有保障",
        },
      ],
      TongDaoLists:[
        {
          id:0,
          image: require("@/img/Jkxkz/icon01.jpg"),
          name:"免税店",
         
        },
        {
          id:1,
          image: require("@/img/Jkxkz/icon02.jpg"),
          name:'出口企业',
        
        },
        {
          id:2,
          image: require("@/img/Jkxkz/icon03.jpg"),
          name:'外贸公司',
       
        },
        {
          id:3,
          image: require("@/img/Jkxkz/icon04.jpg"),
          name:'进口超市',
  
        }
      ],
      TongDaoLists1:[
        {
          id:0,
          image: require("@/img/Jkxkz/icon05.jpg"),
          name:"11年服务经验",
          text:'服务超1W+客户'
        },
        {
          id:1,
          image: require("@/img/Jkxkz/icon06.jpg"),
          name:'经验丰富，不走弯路',
          text:'办理下证快'
        },
        {
          id:2,
          image: require("@/img/Jkxkz/icon07.jpg"),
          name:'1V1专人服务',
          text:'24小时跟踪办理'
        },
        {
          id:3,
          image: require("@/img/Jkxkz/icon08.png"),
          name:'透明报价收费',
          text:'办理不成功则退款'
        }

      ],
    };
  },
//   {
//     'code': 20000,
//     'message': 'message',
//     'data': 'data',
// }
  methods: {
    onSubmit(values) {
      console.log("submit", values);
      add_xkz({"name":values.公司名称,"phono":values.电话号码}).then((response)=>{
        // console.log("1111",);
        alert(response.message)
        // if(response.data>1){
        //   alert("提交成功")
        
        // }else{
        //   alert("提交失败")
        // }
        // console.log(response.data)
      })
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    //  online_advice(){
    //   this.$GM.as()
    // },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.TongDaoListsbox{
  padding-bottom: 30px;
  padding-top: 20px;
  background-color: #fff;
}
.TongDaoLists1{
  .Tdboxmallbox1 {
    width: 350px;
    display: flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
  em{
    font-style: normal;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #1f1f1f;
    font-weight: bold;
  }
  span{
     font-style: normal;
     font-style: normal;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    color: #5d5d5d;
  }
  }
  
}
.TongDaoLists{
  .Tdboxmallbox {
    text-align: center;
    width: 128px;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
  em{
    font-style: normal;
    text-align: center;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #1f1f1f;
    font-weight: bold;
  }
  span{
     font-style: normal;
     font-style: normal;
    text-align: center;
    display: block;
    margin-bottom: 5px;
    color: #5d5d5d;
  }
  }
  
}
.tongdaobigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  margin: 0 auto;
  .tdnbox {
    text-align: center;
    position: relative;
    .tdnbox-from {
      position: absolute;
      top: 60px;
      left: 11%;
      .van-cell {
        background-color: transparent;
      }
    }
  }
}
.wenbox {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  .topbox {
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .bottombox {
    line-height: 18px;
    color: #616161;
  }
}
.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      font-weight: bold;
      i {
        color: #d92119;
        display: inline-block;
        line-height: 24px;
        border-bottom: 1px solid #d92119;
        margin-bottom: 10px;
      }
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}
.fanweibox,
.shipingbox,
.cailiaobox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
    text-align: center;
    line-height: 20px;
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
  .dltextbox {
    position: absolute;
    color: #fff;
    line-height: 25px;
    padding: 20px 50px;
    width: 215px;
    top: 0;
    left: 17%;
  }
}
</style>