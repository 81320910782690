<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Sbzc/banner.png" alt="" width="100%" />
    </div>
    <!-- 我们可以提供的商标服务 -->
    <div class="cleadox"></div>
    <div class="titlebox">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们可以提供的商标服务</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in SBZCGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 商标注册流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>商标注册流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Sbzc/tu01.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
     <!-- TAB切换 -->
    <div class="TABbox">
      <div class="titleicon">
        <img src="../img/home/Ticon01.png" alt="" width="25px" height="25px" />
        <span>一站式工商服务</span>
      </div>
      <van-tabs >
        <van-tab title="工商服务">
          <div  class="tabonebox">
              <van-grid :column-num="2">
                <van-grid-item v-for="item in ZLlists" :key="item.id">
                <img :src="item.image" alt="" width="160" height="160" />
                <i style="margin-top: 5px">
                    <img src="../img/Gsbg/br.png" alt="" />
                </i>
                <span style="margin-top: 5px">{{ item.name }}</span>
                </van-grid-item>
            </van-grid>
          </div>
        </van-tab>
        <van-tab title="财税服务">
          <div  class="tabonebox">
           <van-grid :column-num="2">
                <van-grid-item v-for="item in ZLlists" :key="item.id">
                <img :src="item.image" alt="" width="160" height="160" />
                <i style="margin-top: 5px">
                    <img src="../img/Gsbg/br.png" alt="" />
                </i>
                <span style="margin-top: 5px">{{ item.name }}</span>
                </van-grid-item>
            </van-grid>
          </div>
        </van-tab>
      </van-tabs>
      <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    </div>
    <!-- 商标注册所需资料 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>商标注册所需资料</span>
    </div>
    <div class="TongDaoListsbox">
      <van-grid :border="false" :column-num="2">
        <van-grid-item  v-for="item in TongDaoLists" :key="item.id" class="TongDaoLists">
         
            <i><img :src="item.image" alt="" width="120px" /></i>
            <em>{{item.name}}</em>
         <span>{{item.text}}</span>
          
        </van-grid-item> 
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
     <!-- 互联网文化、药品销售相关许可证种类 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>互联网文化、药品销售相关许可证种类</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Sbzc/tu07.png" alt="" width="350" />
      </div>
      <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Sbzc/tu08.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Sbzc/tu09.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Sbzc/tu10.png" alt="" width="350" />
      </div>
       
    </div>

    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 我们的优势 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们的优势</span>
    </div>
    <div class="Iconitembigbox">
      <div class="Iconitembox" v-for="item in Iconitembox" :key="item.id">
        <div class="left">
          <img :src="item.image" alt="" width="72px" />
        </div>
        <div class="right">
          <i>{{ item.title }}</i>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司变更常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>商标注册常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from '@/components/Bottom.vue';
import Fixd from './Fixd.vue';
import Header from './header.vue';
export default {
    components:{ Bottom, Fixd, Header },
  data() {
    return {
        TongDaoLists:[
        {
          id:0,
          image: require("@/img/Sbzc/icon01.png"),
          name:"丰富经验",
          text:'深耕广州市场10多年拥有良好政企关系 '
        },
        {
          id:1,
          image: require("@/img/Sbzc/icon02.png"),
          name:'尊崇服务',
          text:'一对一尊崇客户服务24小时随时候命'
        },
        {
          id:2,
          image: require("@/img/Sbzc/icon03.png"),
          name:'专业团队',
          text:' 200多人专业团队为您保驾护航'
        },
        {
          id:3,
          nub:'4',
          image: require("@/img/Sbzc/icon04.png"),
          text:' 专业技术保障客户资料免遭外泄'
        }
      ],
      Wentxtlists: [
        {
          id: 0,
          title: "商标注册需要备那些材料？",
          text: " 商标注册需要备那些材料？",
        },
        {
          id: 1,
          title: "通过商标代理组织有那些好处？",
          text: "发业有一支经验丰富商标服务团队，快捷省心，安全有保障。",
        },
        {
          id: 2,
          title: "商标可以使用繁体字吗？",
          text: " 繁体字是可以注册商标的，但是注册商标时必须书写正确规范，不得使用错字、禁止",
        },
        {
          id: 3,
          title: "什么是R和TM？",
          text: " TM是已经拿到受理通知书的商标并可以使用，R是下发了正式商标证书的商标。",
        },
      ],
      SBZCGridLists: [
        {
          id: 0,
          image: require("@/img/Sbzc/dl1.png"),
          name: "商标注册",
        },
        {
          id: 1,
          image: require("@/img/Sbzc/dl2.png"),
          name: "商标异议",
        },
        {
          id: 2,
          image: require("@/img/Sbzc/dl3.png"),
          name: "商标转让",
        },
        {
          id: 3,
          image: require("@/img/Sbzc/dl4.png"),
          name: "商标变更",
        },
        {
          id: 4,
          image: require("@/img/Sbzc/dl5.png"),
          name: "商标驳回复审",
        },
        {
          id: 5,
          image: require("@/img/Sbzc/dl6.png"),
          name: "商标续展",
        },
        {
          id: 6,
          image: require("@/img/Sbzc/dl7.png"),
          name: "商标续展",
        },
        {
          id: 7,
          image: require("@/img/Sbzc/dl8.png"),
          name: "立即咨询",
        },
      ],
      ZLlists: [
        {
          id: 0,
          image: require("@/img/Sbzc/tu03.png"),
          name: "营业执照正副本",
        },
        {
          id: 1,
          image: require("@/img/Sbzc/tu04.png"),
          name: "委托书",
        },
        {
          id: 2,
          image: require("@/img/Sbzc/tu05.png"),
          name: "清晰的商标标识",
        },
        {
          id: 3,
          image: require("@/img/Sbzc/tu06.png"),
          name: "申请书",
        }
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Gsbg/icon01.png"),
          title: "专业团队",
          text: "专业团队办理，专业人做专业事，效率高",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/icon02.png"),
          title: "风险分析",
          text: "公司起名有大数据分析，公司字号经过我们研发的大数据分析，系统过滤风险，降低侵权风险",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/icon03.png"),
          title: "一站式服务",
          text: "一站式全面企业服务支持，注册公司、商标申请保护、版权登记保护、代理记账、发票托管等业务全面覆盖企业运用基本需求",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/icon04.png"),
          title: "售后保障",
          text: "售后有保障，系统监管办理进度、管理员负责管理、专员负责办理、办理进度可追踪，进度有保障。让办理变得简单高效有保障",
        },
      ],
    };
  },

  methods: {
    // online_advice(){
    //   this.$GM.as()
    // },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.TongDaoListsbox{
  padding-bottom: 30px;
  padding-top: 20px;
  background-color: #fff;
}
.TongDaoLists{
  i{
    
    margin-bottom: 5px;
  }
  em{
    font-style: normal;
    text-align: center;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  span{
     font-style: normal;
     font-style: normal;
    text-align: center;
    display: block;
    margin-bottom: 5px;
    width: 80%;
    line-height: 20px;
  }
}
.TABbox {
  margin-top: 20px;
  margin-bottom: 10px;
  ::v-deep .van-tabs__line {
    background-color: transparent;
  }
  ::v-deep .van-tab--active {
    background: url(../img/home/tabbg.png) center 2px no-repeat;
    background-size: 60%;
    color: #fff;
  }
  .tabonebox {
    padding: 10px 20px;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.wenbox{
    padding: 20px 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    .topbox{
        margin-bottom: 20px;
        span{
            font-size: 14px;
            font-weight: bold;
            margin-left: 5px;
        }
    }
    .bottombox{
        line-height: 18px;
        color: #616161;
    }
}
.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      font-weight: bold;
      i {
        color: #d92119;
        display: inline-block;
        line-height: 24px;
        border-bottom: 1px solid #d92119;
        margin-bottom: 10px;
      }
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.ZLlists {
  background-color: #fff;
  padding-bottom: 30px;
  i {
    margin: 10px 0;
  }
}
.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}
.fanweibox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
</style>