<template>
  <div class="Spxkz">
    <SPXKZ />
  </div>
</template>
<script>
import SPXKZ from "@/components/SPXKZ.vue";
export default {
  components: { SPXKZ },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.Spxkz {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #efefef;
  overflow-y: scroll;
}
</style>