<template>
  <div class="AIbox">
    <AI />
  </div>
</template>
<script>
import AI from "@/components/AI.vue";
export default {
  components: { AI },
  data() {
    return {
     
    };
  },

  methods: {
   
  },
};
</script>
<style lang="scss" scoped>
.AIbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #efefef;
  overflow-y: scroll;
}
</style>