<template>
  <div class="SearchPopupbox">
    <van-search
      v-model="SearchVul"
      show-action
      :placeholder="PlaceholderVul"
      @search="onSearch"
      @cancel="onCancel"
      @input ="onInput"
    />
  
  <HistoryHot v-if="blockShow==0" :HistoryHotListData = "HistoryHotListData" :hotKeywordListData = "hotKeywordListData" />
  <SearchTipsList v-else-if="blockShow==1" :searchTipsList = "searchTipsList"/>
  <SearchProducts v-else-if="blockShow==2" />

  </div>
</template>
<script>
import HistoryHot from "@/components/HistoryHot.vue"
import SearchTipsList from "@/components/SearchTipsList.vue"
import {GitSearchPopupData,GitSearchTipsListData} from "@/request/api"
import SearchProducts from '@/components/SearchProducts.vue';
export default {
  components:{HistoryHot,SearchTipsList, SearchProducts},
  data() {
    return {
      SearchVul: "",
      PlaceholderVul:'',
      blockShow:2,
      HistoryHotListData:[],
      hotKeywordListData:[],
      searchTipsList:[]
    };
  },
created(){
  GitSearchPopupData().then(res=>{
    if(res.errno == 0){
      this.PlaceholderVul = res.data.defaultKeyword.keyword
      this.HistoryHotListData =res.data.historyKeywordList
      this.hotKeywordListData =res.data.hotKeywordList
    }
  })
},
  methods: {
    onSearch() {
      this.blockShow = 2
    },
    onCancel() {
     this.$router.go(-1);
    },
    onInput(val){
     this.blockShow = 1
      GitSearchTipsListData({keyword:val}).then(res=>{
        if(res.errno == 0){
          this.searchTipsList = res.data
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.SearchPopupbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #efefef;
}
</style>