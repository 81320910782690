<template>
    <div class="SearchPopupbox">
        <!-- 标题 -->
        <div>
            <van-nav-bar title="查询结果" left-text="返回" left-arrow @click-left="onClickLeft" />
        </div>
        <!-- 内容 -->
        <div class="cxjgbox">
            <div class="cxtitleboxaaa" v-for="(item,index) in xt_list" :key="index">
                <span class="spanleft" v-if="item.gs_name" v-html="showDate(item.gs_name)"></span>
                <span class="spanleft" v-else="item.gs_name==''" v-html="showDate(item.name)"></span>
                <span>{{item.creater_at}}</span>
            </div>
        </div>
        <!-- 分页器 -->
        <div class="fybox">
            <van-pagination v-model="currentPage" :total-items="z_xtym"  @change="xtym_hqym()"/>
        </div>
    </div>
</template>
<script>
import router from '@/router';
import { selchaxun } from '@/request/api';

export default {

    data() {
        return {
            currentPage:1,
            z_xtym:0,
            xt_list:[],
            ListQuery:{
                page :1,
                limit : 10,
                zh1:''
            },
        };
    },
    created(){
        console.log(router.history.current.query.name)
        if(router.history.current.query.name){
            this.ListQuery.zh1=router.history.current.query.name
            this.selchaxun()
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        selchaxun(){
            selchaxun(this.ListQuery).then((response)=>{
                this.z_xtym=response.data.xttotal
                this.xt_list=response.data.xt
            })
        },
        //相同字号中页码切换
        xtym_hqym(){
        this.ListQuery.page=this.currentPage
        selchaxun(this.ListQuery).then((response)=>{
            this.z_xtym=response.data.xttotal
            this.xt_list=response.data.xt
        })
        },

        showDate(val) {
            // console.log(this.bhbj)
            if (this.ListQuery.zh1 ==this.ListQuery.zh1){
            // this.getList()
            if (
                (val||'').indexOf(this.ListQuery.zh1) !== -1 && 
                this.ListQuery.zh1!== ''
            ) {
                return val.replace(
                this.ListQuery.zh1,
                '<font color="#f00">' + this.ListQuery.zh1+ '</font>'
                )
            } else {
                return val
            }
            }else{
                return val;
            }
        },


    },
};
</script>
<style lang="scss" scoped>
.SearchPopupbox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #efefef;
    .cxjgbox {
        padding: 20px 15px;
        background-color: #fff;
        height: 80%;
      
            .cxtitleboxaaa {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                span {
                    color: #444444;
                    em {
                        color: #f42121;
                    }
                }

                span:nth-child(2) {
                    color: #898989;
                }
            }
        
    }

}
</style>