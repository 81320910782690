<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Gszc/banner.png" alt="" width="100%" />
    </div>
    <!-- 表单模块 -->
    <div class="cleadox"></div>
    <div class="Fromebox">
      <div class="zihaobig">
        <h4>发业免费查名系统</h4>
        <span>（同步官方工商核名系统）</span>
      </div>
      <div class="kelibox">
        <div>今日核名<span>{{rs}}</span> 次</div>
        <div>累计核名<span>{{sl}}</span> 次</div>
      </div>
      <div class="dzname">
        <!-- <span>广州</span> -->
        <van-field v-model="qy" name="区域" placeholder="广州"  />
      </div>
      <van-form @submit="onSubmit">
        <van-field v-model="username" name="字号" placeholder="请输入您的字号  如：智远"
          :rules="[{ required: true, message: '请输入您的字号  如：智远' }]" />
        <van-field v-model="password" name="行业" placeholder="请输入您的行业表述  如：视觉科技"
          :rules="[{ required: true, message: '请输入您的行业表述  如：视觉科技' }]" />
        <div class="dzname">
          <!-- <span>有限公司</span> -->
          <van-field v-model="gslx" name="区域" placeholder="有限公司"  />
        </div>
        <div style="margin: 16px;">
          <van-button round block type="danger" native-type="submit">免费查询</van-button>
          <span class="jiyu">使用免费查询功能，该查询功能即时同步官方工商核名系
            统,所查的结果为工商核名系统查询结果</span>
        </div>
      </van-form>
    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 如何选择注册什么类型的主体 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>如何选择注册什么类型的主体</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in GSZCridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>

    <!-- 不同类型的区别和利弊 -->
    <div class="TABbox">
      <div class="titlebox" style="margin-top: 10px">
        <i>
          <img src="../img/Gsbg/lr.jpg" alt="" />
        </i>
        <span>不同类型的区别和利弊</span>
      </div>
      <van-tabs>
        <van-tab title="有限公司">
          <div class="tabonebox">
            <h4> 有限公司有什么利弊 </h4>
            利端：责任有限，经营风险对投资者的影响可控（投资者以认缴的出资金额为承担责任）
            股权转让方便（随时可以转让股权）
            有助商业活动（公司在交易过程中更具保障性，有助开展业务）
            相比股份公司、集团公司而言更适合创业对象，因为股份公司、集团公司对财务要求更加严格规范，运作成本高不适合创业阶段，一般后期发展壮大再进行股份制改革
            盈利可以积累在公司，对分红的个人所得税而言可以自由选择缴纳时间，个人独资企业和合伙企业盈利是马上要交个人所得税不能自由控制
            在现行税收优惠政策下，企业所得税的税负很低，比个独、合伙企业需要交的个税而言可能会低很多<br><br>

            弊端：需要规范管理需要健全财务管理，运行成本相比个体高
            相比合伙企业、个人独资企业而言多一个企业所得税税种
          </div>
        </van-tab>
        <van-tab title="个人独资企业">
          <div class="tabonebox">
            <h4>个人独资企业有什么利弊</h4>
            利端：不存在企业所得税，赚到的钱纳个人所得税后可以直接进入投资者腰包
            在核定征收的征收方式下，一般税负会比较低，但目前税收环境明显不支持核定征收方式<br><br>

            弊端：投资者需要承担无限连带责任，需要承担高的经营风险
            在不能核定征收的情况下，财务管理不健全出现的税负会非常高，比如企业开销无法拿回合法票据入账，利润虚高产生高额个人所得税
            目前税务没有个人所得税在经营所得项目的重大优惠政策，这个意味着税负往往会很高
          </div>
        </van-tab>
        <van-tab title="合伙企业">
          <div class="tabonebox">
            <h4> 合伙企业有什么利弊</h4>
            利端：对于占股比较低的投资者想取得控制权来说，成立有限合伙企业是比较理想的方式，利用有限合伙人不能参与企业经营活动，普通合伙人才可以参与企业
            经营活动的规定来取得控制权，但不好的地方是作为普通合伙人需要承担无限连带责任<br><br>

            弊端：和个人独资企业一样，主要的弊端是税负可能会很高
          </div>
        </van-tab>
        <van-tab title="个体户">
          <div class="tabonebox">
            <h4>个体户有什么利弊</h4>
            利端：一般适用小型经营店面，比如便利店、小餐馆等；好处是简单。<br><br>
            弊端：是不利于大区域业务开展
          </div>
        </van-tab>
      </van-tabs>
      <div class="dibubox">
        <span class="red">以上是选择注册什么行业利弊的介绍，简单来说重点考虑的细节在于以下三个方面：</span>
        <van-grid :border="false" :column-num="3">
          <van-grid-item>
            <img src="../img/Gszc/dl7.png" width="90" />
            <span>承担的责任</span>
          </van-grid-item>
          <van-grid-item>
            <img src="../img/Gszc/dl8.png" width="90" />
            <span>税负</span>
          </van-grid-item>
          <van-grid-item>
            <img src="../img/Gszc/dl9.png" width="90" />
            <span>运行成本</span>
          </van-grid-item>
        </van-grid>
      </div>
       <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    </div>
    <!-- 代理记账服务流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>广州注册公司流程及费用</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Gszc/tu01.png" alt="" width="350" />
      </div>

    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司注册完成后拿到的资料 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>公司注册完成后拿到的资料</span>
    </div>
    <div class="ZLlists">
      <van-grid :column-num="2">
        <van-grid-item v-for="item in ZLlists" :key="item.id">
          <img :src="item.image" alt="" width="160" height="160" />
          <i>
            <img src="../img/Gsbg/br.png" alt="" />
          </i>
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司注册完成后需要做什么 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>公司注册完成后需要做什么</span>
    </div>
    <div class="jztextbigbox">
      <div class="jztextbox">
        <span>银行开户</span>
        <em>
          什么是基本户？ <br>
          基本户是企业的主要的对公账户，主要用来缴纳税金、收付货款、发放工资等用途。<br><br>

          怎样选择银行？<br>
          每个银行的收费和资金使用灵活度不一样，开户前需要和银行沟通清楚基本情况。
        </em>
      </div>
      <div class="jztextbox">
        <span>代理记账</span>
        <em>
          公司成立后要及时进行税务报到<br>
          公司成立后，一般要求10个工作日内去所属税务机关报到，超时未报到的企业有可能会
          被罚款。<br><br>

          成立后第一个月起，需要会计每月记账报税。<br>
          公司成立后第一个月起，税务机关就会要求公司每月进行记账报税，没有收入支出也需
          要报税。
        </em>
      </div>
      <div class="jztextbox">
        <span>商标注册</span>
        <em>
          注册商标的意义<br>
          尽早将品牌注册为商标，能够帮助创业者保护品牌和规避商标法律风险。<br><br>

          注册商标注意事项：<br>
          商标注册需先由商标顾问制定商标注册方案，预估商标注册风险及保护类别。
        </em>
      </div>
      <div class="jztextbox">
        <span>社保开户</span>
        <em>
          开设企业社保账户，为员工缴纳五险一金<br>
          《社会保险法》规定，用人单位应当自成立之日起三十日内申请办理社会保险登记。<br><br>

          未能及时缴纳的，由征收机构责令自欠缴之日起向用人单位按日加收万分之五的滞纳金。<br>
          一般建议公司自行办理社保开户，或委托服务商代办社保开户。
        </em>
      </div>
    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 我们的优势 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们的优势</span>
    </div>
    <div class="Iconitembigbox">
      <div class="Iconitembox" v-for="item in Iconitembox" :key="item.id">
        <div class="left">
          <img :src="item.image" alt="" width="72px" />
        </div>
        <div class="right">
          <i>{{ item.title }}</i>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司注册常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>公司注册常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
     <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import { selchubu, seltime } from '@/request/api';
import Fixd from "./Fixd.vue";
import Header from "./header.vue";

export default {
  components: { Bottom, Fixd, Header },
  data() {
    return {
      rs:0,
      sl:0,
      qy:'',
      username: '',
      password: '',
      gslx:'',
      ZLlists: [
        {
          id: 0,
          image: require("@/img/Gsbg/tu03.png"),
          name: "营业执照正副本",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/tu04.png"),
          name: "公司印章",
        },
        {
          id: 2,
          image: require("@/img/Gszc/tu05.png"),
          name: "公司章程",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/tu06.png"),
          name: "开业通知书",
        }
      ],
      Wentxtlists: [
        {
          id: 0,
          title: "注册资金多少到底有什么不同?",
          text: " 注册资金是投资者承诺成立企业所承担的投资金额，这个意味着需要承担多大的责任。",
        },
        {
          id: 1,
          title: "经营范围选择有什么讲究吗?",
          text: "经营范围可以按需选择没有限制，如果是涉及需要许可经营的项目需要办理对应许可证，如 果不涉及许可经营项目就不要选择该类的经营范围。",
        },
        {
          id: 2,
          title: "注册需要多久时间?",
          text: " 这个具体要看办理事情的受理单位进度，目前情况是常规资料齐全达到可以申请的情况下一般 三个工作日可以成功下执照。",
        },
        {
          id: 3,
          title: "一个自然人可以注册多少家公司?",
          text: " 一个自然人只能注册一家一人有限公司，可以注册多家个人独资企业及个体户，多人有限公司没有限制注册数量。",
        },
        {
          id: 4,
          title: "没有注册地址可以注册公司吗?",
          text: " 必须要有注册地址，如果没有注册地址可以选择挂靠地址方式注册。",
        },
        {
          id: 5,
          title: "自己办理可以去办理吗?",
          text: " 可以的，选择我们代办更加省事，我们熟悉流程、材料填写，熟悉各种常见问题，同时有专 人负责推进工作，省心省事。",
        },
      ],
      GSZCridLists: [
        {
          id: 0,
          image: require("@/img/Gszc/dl1.png"),
          name: "有限公司",
        },
        {
          id: 1,
          image: require("@/img/Gszc/dl2.png"),
          name: "个人独资企业",
        },
        {
          id: 2,
          image: require("@/img/Gszc/dl3.png"),
          name: "合伙企业",
        },
        {
          id: 3,
          image: require("@/img/Gszc/dl4.png"),
          name: "个体户",
        },
        {
          id: 4,
          image: require("@/img/Gszc/dl5.png"),
          name: "股份公司",
        },
        {
          id: 5,
          image: require("@/img/Gszc/dl6.png"),
          name: "集团公司",
        }
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Gsbg/icon01.png"),
          title: "专业团队",
          text: "专业团队办理，专业人做专业事，效率高",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/icon02.png"),
          title: "风险分析",
          text: "公司起名有大数据分析，公司字号经过我们研发的大数据分析，系统过滤风险，降低侵权风险",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/icon03.png"),
          title: "一站式服务",
          text: "一站式全面企业服务支持，注册公司、商标申请保护、版权登记保护、代理记账、发票托管等业务全面覆盖企业运用基本需求",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/icon04.png"),
          title: "售后保障",
          text: "售后有保障，系统监管办理进度、管理员负责管理、专员负责办理、办理进度可追踪，进度有保障。让办理变得简单高效有保障",
        },
      ],
    };
  },
  created(){
    this.seltime()
  },
  methods: {
    onSubmit(values) {
      console.log('submit', values);
        if(this.qy==''){
          this.qy='广州'
        }
        if(this.gslx==''){
          this.gslx='有限公司'
        }
        let url = './AI?qy='+this.qy+'&zh1='+values.字号+'&hy='+values.行业+'&lx='+this.gslx; 
        window.location.href=url  //页面跳转
      
    },
   
    online_advice(){
      this.$GM.as()
    },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
    seltime(){
      seltime().then((response)=>{
        this.rs=response.data.rs
        this.sl=response.data.zs
      })
    }
  },
};
</script>
<style lang="scss"  scoped>
.TABbox {
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #fff;
  padding-bottom: 30px;

  ::v-deep .van-tabs__line {
    background-color: transparent;
  }

  ::v-deep .van-tab--active {
    background: url(../img/home/tabbg.png) center 4px no-repeat;
    background-size: 100%;
    color: #fff;
  }

  .tabonebox {
    width: 330px;
    padding: 10px 20px;
    background-color: #fff;
    line-height: 20px;
    margin: 20px auto;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);

    h4 {
      text-align: center;
      margin-bottom: 5px;
    }

  }
  .dibubox {
    .red{
      color: #d92119 ;
      display: block;
      padding: 5px 15px;
      line-height: 18px;
    }
  }
}

.Fromebox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;

  .jiyu {
    color: #636363;
    display: block;
    width: 90%;
    margin-top: 5px;
    line-height: 18px;
    margin: 5px auto;
  }
}

.zihaobig {
  background-color: #fff;
  padding-bottom: 30px;

  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    text-align: center;
    display: block;
    margin-bottom: 5px;
  }
}

.kelibox {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  background-color: #fff;
  color: #636363;
  margin-bottom: 5px;

  span {
    color: #de1e1e;
  }
}

.dzname {
  padding: 10px 15px;
  height: 24px;
  line-height: 24px;
  background-color: #fff;
  border-bottom: 1px solid #ebedf0;
}


.ZLlists {
  background-color: #fff;
  padding-bottom: 30px;

  i {
    margin: 10px 0;
  }
}

.jztextbigbox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;

  .jztextbox {
    width: 320px;
    padding: 50px 10px 20px;
    border: 1px solid #efefef;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0 0 4px #d1d1d1;
    border-radius: 3px;
    position: relative;
    margin-bottom: 20px;

    span {
      position: absolute;
      top: 15px;
      left: -3px;
      display: block;
      background: url(../img/Dljz/tl.png) no-repeat;
      width: 100px;
      height: 35px;
      background-size: contain;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
    }

    em {
      font-style: normal;
      color: #555555;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.wenbox {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;

  .topbox {
    margin-bottom: 20px;

    span {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .bottombox {
    line-height: 18px;
    color: #616161;
  }
}

.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;

  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;

    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }

    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      font-weight: bold;

      i {
        color: #d92119;
        display: inline-block;
        line-height: 24px;
        border-bottom: 1px solid #d92119;
        margin-bottom: 10px;
      }

      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}

.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;

  img {
    margin-right: 5px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}

.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;

  i {
    margin-right: 5px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}

.fanweibox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;

  span {
    margin-top: 5px;
  }
}

.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;

  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
</style>