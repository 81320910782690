<template>
  <div class="NewHomebox">
    <!-- 头部 -->
    <div>
      <Header />
    </div>
    <!-- 轮播图 -->
  <van-swipe class="my-swipe" :autoplay="2000" indicator-color="red">
      <van-swipe-item
        v-for="item in bannerList"
        :key="item.id"
        @click="online_advice()"
      >
        <img :src="item.image_url" width="100%" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- 九宫格导航 -->
    <van-grid :border="false"> 
      <van-grid-item
        v-for="item in GridLists"
        :key="item.id"
        @click="$router.push(item.hettpurl)"
      >
        <img :src="item.image" alt="" width="70" />
        <span>{{ item.name }}</span>
      </van-grid-item>
    </van-grid> 
    <!-- 不同类型的主体  -->
    <div class="subject">
      <div class="subject_title"><span>不同类型主体</span> 利弊和区别</div>
      <div>
        <van-tabs v-model="TABactive" line-height="0px" title-active-color="#cf1c1c">
          <van-tab v-for="(item,index) in TABactiveLists " :key="index" :title="item.title" >
              <div class="TABactiveShow">
                  <span class="title">{{item.texttitle}}</span>
                  <i class="l_box">利端</i>
                  <div class="l_center" v-html="item.litext">
                    <!-- {{item.litext}} -->
                  </div>
                  <i class="b_box">弊端</i>
                  <div class="b_center" v-html="item.bitext">
                     <!-- {{item.bitext}} -->
                  </div>
              </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="TABbottom">
        <p>以上是选择注册什么行业利弊的介绍，简单来说重点考虑的细节在于以下三个方面：</p>
        <div class="TABbtn">
          <span>承担的责任</span>
          <span>税负</span>
          <span>运行成本</span>
        </div>
      </div>
      <!-- 点击拨打电话 -->
      <div class="call" style="background-color: #cf1c1c;position: relative;">
        <img src="../img/newhome/call.png" width="100%" @click="Botell">
        <div style="position: absolute; top: 15px; left: 20px;">
            <input type="text" placeholder="请输入您的电话号码" style="border: none;height: 28px;padding-left: 15px;">
        </div>
      </div>
    </div>
    <!-- 选择类型的主体 -->
    <div>
      <img src="../img/newhome/bg01.png" alt="" width="100%">
    </div>
    <div class="LxListsClass" >
      <van-grid :border="false" :column-num="3" > 
      <van-grid-item
        v-for="item in LxLists"
        :key="item.id"
      >
        <img :src="item.image" alt="" width="60" />
        <span style="color:#fff">{{ item.name }}</span>
      </van-grid-item>
    </van-grid>

    </div>
     <!-- 点击拨打电话 -->
      <div class="call"  style="padding-top: 20px; position: relative;">
        <img src="../img/newhome/call.png" alt="" width="100%" @click="Botell">
        <div style="position: absolute; top: 26px; left: 20px;">
            <input type="text" placeholder="请输入您的电话号码" style="border: none;height: 28px;padding-left: 15px;">
        </div>
      </div>
   <!-- 常见问题 -->
   <div class="Problem">
    <div class="Ptitle"><span>公司注册</span> 常见问题</div>
    <van-collapse v-model="activeName" accordion :border="false">
      <van-collapse-item v-for="(item,index) in PtitleLists" :key="index" :title="item.texttitle" :name="item.name">
        <div  v-html="item.rtext"></div>
      </van-collapse-item>
      <!-- <van-collapse-item title="标题2" name="2">内容</van-collapse-item>
      <van-collapse-item title="标题3" name="3">内容</van-collapse-item> -->
    </van-collapse>
   </div>
   <div>
      <img src="../img/newhome/bg02.png" alt="" width="100%">
    </div>
    <div>
      <img src="../img/newhome/bg03.png" alt="" width="100%">
    </div>
    <div>
      <img src="../img/newhome/bg04.png" alt="" width="100%">
    </div>
     <div>
      <img src="../img/newhome/bg05.png" alt="" width="100%">
    </div>
     <div style="position: relative;">
      <img src="../img/newhome/bg06.png" alt="" width="100%">
      <div style="position: absolute; bottom: 0; left: 0px;">
          <!-- 点击拨打电话 -->
          <div class="call"  style="padding-top: 20px; position: relative; background-color: transparent;">
            <img src="../img/newhome/call.png" alt="" width="100%" @click="Botell">
            <div style="position: absolute; top: 26px; left: 20px;">
                <input type="text" placeholder="请输入您的电话号码" style="border: none;height: 28px;padding-left: 15px;">
            </div>
          </div>
      </div>
    </div>
     <div style="margin-top: -3px; position: relative;">
      <img src="../img/newhome/bg07.png" alt="" width="100%">
      <div style="position: absolute; bottom: 0; left: 0px;">
          <!-- 点击拨打电话 -->
          <div class="call"  style="padding-top: 20px; position: relative; background-color: transparent;">
            <img src="../img/newhome/call.png" alt="" width="100%" @click="Botell">
            <div style="position: absolute; top: 26px; left: 20px;">
                <input type="text" placeholder="请输入您的电话号码" style="border: none;height: 28px;padding-left: 15px;">
            </div>
          </div>
      </div>
    </div>
    <div>
      <img src="../img/newhome/bg08.png" alt="" width="100%">
    </div>
    <div class="NeedBox">
      <div class="NeedBoxTitle">
        <i>公司注册完成后</i>
        <span>您还需要做什么</span>
      </div>
      <div class="NeedBoxModelbox">
        <van-tabs v-model="TABactive" line-height="0px" title-active-color="#cf1c1c">
          <van-tab v-for="(item,index) in NeedBoxModelbox " :key="index" :title="item.title" >
              <div class="TABactiveShow">
                  <span class="title">{{item.texttitle}}</span>
                  <div class="l_center" v-html="item.litext"></div>
              </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="NeedBoxBottom">
        <!-- 点击拨打电话 -->
        <div class="call" style="background-color: #cf1c1c;position: relative;">
          <img src="../img/newhome/call.png" width="100%" @click="Botell">
          <div style="position: absolute; top: 15px; left: 20px;">
              <input type="text" placeholder="请输入您的电话号码" style="border: none;height: 28px;padding-left: 15px;">
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: -3px; position: relative;">
         <img src="../img/newhome/bg09.png" alt="" width="100%"></img>
      <div class="Service">
          <div class="ServiceTitle">
            发业为您提供<i>一站式服务</i>
          </div>
          <div class="ServiceBox">
            <van-grid :border="false"> 
              <van-grid-item
                v-for="item in ServiceLists"
                :key="item.id"
              >
                <img :src="item.image" alt="" width="45" />
                <span>{{ item.name }}</span>
              </van-grid-item>
            </van-grid>
          </div>
          <div class="ServiceBottom">
             <!-- 点击拨打电话 -->
            <div class="call" style="position: relative; background-color: transparent;">
              <img src="../img/newhome/call.png" width="100%" @click="Botell">
              <div style="position: absolute; top: 15px; left: 20px;">
                  <input type="text" placeholder="请输入您的电话号码" style="border: none;height: px;padding-left: 15px;">
              </div>
            </div>
          </div>
      </div>
    </div>
    <!-- 发业风采 -->
    <div class="Fybox">
      <div class="Fytitle">
        <i>发业</i>风采
      </div>
      <div class="Fyimg">
        <img src="../img/newhome/bg10.png" alt="" width="100%">
      </div>
      <div class="Fywenzi">
          <span>十多年服务 行业领先</span>
发业集团总部设立在广州珠江新城，是一家集工商注册、代理记账、广州入户、知识产权于一体的大型“企业一站式服务”集团。发业经过10多年的品牌积淀，在广大客户中建立了超卓信誉，并铸就出稳居中国企业服务行业前列的旗舰品牌。
      </div>
    </div>
    <!-- 底部 -->
    <div>
        <Bottom />
    </div>
    <!-- 浮动 -->
    <div>
      <Fixd />
    </div>
  </div>
</template>
<script>
import Header from "@/components/newheader.vue";
import Fixd from "./Fixd.vue";
import Bottom from "./Bottom.vue";
export default {
  components: { Header, Fixd, Bottom },
  data() {
    return {
      activeName: '0',
        TABactive: 0,
         bannerList: [
        {
          id: 0,
          image_url: require("@/img/home/banner.png"),
        },
        {
          id: 1,
          image_url: require("@/img/home/banner01.png"),
        },
         {
          id: 2,
          image_url: require("@/img/home/banner02.png"),
        },
      ],
      GridLists: [
        {
          id: 0,
          image: require("@/img/newhome/icon01.png"),
          name: "公司注册",
          hettpurl: "/Gszc",
        },
        {
          id: 1,
          image: require("@/img/newhome/icon02.png"),
          name: "AI智能起名",
          hettpurl: "/AI",
        },
        {
          id: 2,
          image: require("@/img/newhome/icon03.png"),
          name: "公司变更",
          hettpurl: "/Gsbg",
        },
        {
          id: 3,
          image: require("@/img/newhome/icon04.png"),
          name: "代理记账",
          hettpurl: "/Dljz",
        },
        {
          id: 4,
          image: require("@/img/newhome/icon05.png"),
          name: "许可证",
          hettpurl: "/Xkz",
        },
        {
          id: 5,
          image: require("@/img/newhome/icon06.png"),
          name: "公司注销",
          hettpurl: "/Gszx",
        },
        {
          id: 6,
          image: require("@/img/newhome/icon07.png"),
          name: "商标注册",
          hettpurl: "/Sbzc",
        },
        {
          id: 7,
          image: require("@/img/newhome/icon08.png"),
          name: "关于发业",
          hettpurl: "/Gyfy",
        },
      ],
      ServiceLists:[
        {
          id: 0,
          image: require("@/img/newhome/icon15.png"),
          name: "公司注册",
        },
        {
          id: 1,
          image: require("@/img/newhome/icon16.png"),
          name: "地址挂靠",
        },
        {
          id: 2,
          image: require("@/img/newhome/icon17.png"),
          name: "执照加急",
        },
        {
          id: 3,
          image: require("@/img/newhome/icon18.png"),
          name: "特殊核名",
        },
        {
          id: 4,
          image: require("@/img/newhome/icon19.png"),
          name: "香港公司",
        },
        {
          id: 5,
          image: require("@/img/newhome/icon20.png"),
          name: "公司转让",
        },
        {
          id: 6,
          image: require("@/img/newhome/icon21.png"),
          name: "社保代办",
        },
        {
          id: 7,
          image: require("@/img/newhome/icon22.png"),
          name: "网站建设",
        },
        {
          id: 8,
          image: require("@/img/newhome/icon23.png"),
          name: "特殊审批",
        },
        {
          id: 9,
          image: require("@/img/newhome/icon24.png"),
          name: "代理记账",
        },
        {
          id: 10,
          image: require("@/img/newhome/icon25.png"),
          name: "公司变更",
        },
        {
          id: 11,
          image: require("@/img/newhome/icon26.png"),
          name: "守合同重信用",
        },
        {
          id: 12,
          image: require("@/img/newhome/icon27.png"),
          name: "商标专利",
        },
        {
          id: 13,
          image: require("@/img/newhome/icon28.png"),
          name: "版权登记",
        },
      ],
      LxLists:[
        {
          id: 0,
          image: require("@/img/newhome/icon09.png"),
          name: "有限公司",
        },
        {
          id: 1,
          image: require("@/img/newhome/icon10.png"),
          name: "独资企业",
        },
        {
          id: 2,
          image: require("@/img/newhome/icon11.png"),
          name: "合伙企业",
        },
        {
          id: 3,
          image: require("@/img/newhome/icon12.png"),
          name: "个体户",
        },
        {
          id: 4,
          image: require("@/img/newhome/icon13.png"),
          name: "股份公司",
        },
        {
          id: 5,
          image: require("@/img/newhome/icon14.png"),
          name: "集团公司",
        }
      ],
      TABactiveLists:[
        {
          id:0,
          title:'有限公司',
          texttitle:'有限公司有什么利弊',
          litext:'责任有限，经营风险对投资者的影响可控（投资者以认缴的出资金额为承担责任）<br/> 股权转让方便（随时可以转让股权 ）<br/>有助商业活动（公司在交易过程中更具保障性，有助开展业务）<br/>相比股份公司、集团公司而言更适合创业对象，因为股份公司、集团公司对财务要求更加严格规范，运作成本高不适合创业阶段，一般后期发展壮大再进行股份制改革<br/>盈利可以积累在公司，对分红的个人所得税而言可以自由选择缴纳时间，个人独资企业和合伙企业盈利是马上要交个人所得税不能自由控制<br/>在现行税收优惠政策下，企业所得税的税负很低，比个独、合伙企业需要交的个税而言可能会低很多',
          bitext:'需要规范管理需要健全财务管理，运行成本相比个体高相比合伙企业、个人独资企业而言多一个企业所得税税种'
          
        },
        {
          id:1,
          title:'个体独资',
          texttitle:'个人独资企业有什么利弊',
          litext:'不存在企业所得税，赚到的钱纳个人所得税后可以直接进入投资者腰包<br/>在核定征收的征收方式下，一般税负会比较低，但目前税收环境明显不支持核定征收方式 ',
          bitext:'投资者需要承担无限连带责任，需要承担高的经营风险 <br/> 在不能核定征收的情况下，财务管理不健全出现的税负会非常高，比如企业开销无法拿回合法票据入账，利润虚高产生高额个人所得税<br/> 目前个人所得税在经营所得项目中没有重大优惠政策，这个意味着税负往往会很高'
        },
        {
          id:2,
          title:'合伙企业',
          texttitle:'合伙企业有什么利弊',
          litext:'对于占股比较低的投资者想取得控制权来说，成立有限合伙企业是比较理想的方式，利用有限合伙人不能参与企业经营活动，普通合伙人才可以参与企业 经营活动的规定来取得控制权，但不好的地方是作为普通合伙人需要承担无限连带责任。',
          bitext:'和个人独资企业一样，主要的弊端是税负可能会很高'
        },
        {
          id:3,
          title:'个体户',
          texttitle:'个体户有什么利弊',
          litext:'一般适用小型经营店面，比如便利店、小餐馆等；好处是简单 ',
          bitext:'不好的地方是不利于大区域业务开展'
        }
      ],
      PtitleLists:[
        {
          id:0,
          name:1,
          texttitle:'注册资金多少到底有什么不同?',
          rtext:'现在注册资金是认缴制度，同时也取消了注册资金的最低限额。<br/>虽然注册公司的时候注册资金大小可以按照需要填写，但在确认注册资金大小之前可以先了解到底有什么不同<br/>一：注册资金是投资者承诺成立企业所承担的投资金额，这个意味着需要承担多大的责任。<br/>二：投资者以认缴的注册资金金额来承担有限责任是仅限于公司类型的主体的股东，个人独资企业、合伙企业中的普通合伙人、个体户的投资者是承担无限连带责任<br/>三：认缴的金额可以一直不认缴吗？如果企业没有债务的情况下，认缴的时间是可以由股东自行决定实缴时间的，如果企业的债务人形式债权权利可能会产生实缴的义务履行，还有一种情况是已经实缴的股东是可以要求没履行实缴的股东履行实缴义务，不然未履行实缴义务的股东会存在被投资企业获取的利润无法享受得到分红。原因是公司法中规定了股东必须履行出资义务，如果没有履行义务，股东分红的权利是会受影响的。所以注册资金不能随意填写一定要按照实际情况来填写。'
        },
        {
          id:1,
          name:2,
          texttitle:'经营范围选择有什么讲究吗?',
          rtext:'一、经营范围主要分两大类：许可类和非许可类，许可类的意思是必须办理对应的许可证证才属于合法经营不然就会被罚款。许可类又分前置许可和后置许可，前置许可是指先拿到许可证才可以增加该类经营范围，后置许可是指可以先增加经营范围然后再办理许可证。<br/>二、经营范围选择的数量有限制吗？这个是没有限制的，企业可以更加自己的需要选取经营范围，也可以在经营过程中随时增减。<br/>三、经营范围到底怎么选，需要考虑哪些方面的因素呢？<br/>首先：选择经营范围的时候首先是满足明确的业务需要，避免超经营范围受到处罚，如果选择的经营范围多对公司的形象不造成影响还是可以考虑全面一点尽量把会涉及的都选上。所有的项目都选上也不科学，比如一个科技的公司选择卖化肥的经营范围是不是会让客户觉得不务正业。这个就是所谓的对公司形象造成影响的情况。<br/>其次：经营范围没有选择到位，如果经营过程中发生了经营范围中没有的业务，这时候开出去的发票容易造成开出去的发票被收回的情况。当然一般情况下只要及时做好经营范围的变更就可以了。企业变更经营范围也是很快的，如果是涉及许可类的经营范围的业务就要注意了，这类的业务一旦在没有取得许可证的前提下发生就必然会产生罚款的哦。<br/>以上几点是发业小编为您详细和重点介绍的知识，看完后您是否对经营范围就有深入的了解了。作为创业者必须要了解和掌握哦'
        },
        {
          id:2,
          name:3,
          texttitle:'注册需要多久时间?',
          rtext:'只要资料齐全最快两天能拿到执照。注册申请前需要先准备好股东信息、地址材料，确定公司名称、经营范围等信息。配合政府无纸化办公的模式，现在注册都转为线上办理，股东和重要职位人员都需要进行实名认证，降低被盗用信息注册公司的风险。线上受理更加快捷方便，大大缩短了注册办理的时间。 '
        },
        {
          id:3,
          name:4,
          texttitle:'一个自然人可以注册多少家公司?',
          rtext:'一个自然人可以注册的主体类型有：一人有限公司、个人独资企业、个体户。<br/>一人有限公司是指只有一位股东的有限责任公司，主体是公司，可以按照公司的合法业务进行经营。一个自然人只能注册一家一人有限公司。<br/>个人独资企业是指一人投资经营的企业，企业负责人就是投资者本人，投资人需对企业债务承担无限责任。一个自然人可以注册多家个人独资企业。<br/>个体户可以由一个自然人从事工商业经营，自负盈亏。一个自然人可以注册多家个体户。'
        },
        {
          id:4,
          name:5,
          texttitle:'没有注册地址可以注册公司吗?',
          rtext:'没有注册地址不能注册公司，公司注册必须提交地址材料。如果暂时提供不了符合注册要求的地址，可以选择落地在我司提供的国家认可的孵化器、众创空间地址和集群地址，这类型的地址主要是惠及初创企业和电商企业等，在没有实体店经营的情况下有地址可以申请注册，有专人统一管理，配合银行开户，降低创业者的投入成本，也方便异地管理。'
        },
        {
          id:5,
          name:6,
          texttitle:'自己可以去办理公司注册吗?',
          rtext:'可以的。但是自己办理在注册资金、股权架构、经营范围选择等方面的填报都有隐藏的风险点，代办公司可以根据股东或投资人的实际情况进行个性化指导，降低投资风险，少走弯路，代跑腿，轻松快捷。'
        },
         {
          id:6,
          name:7,
          texttitle:'我可以注册境外的公司吗？',
          rtext:'可以的。中国境内自然人和中国注册的公司都可以在境外进行投资活动，设立公司。'
        },
        {
          id:7,
          name:8,
          texttitle:'外国人可以跟中国人合办公司吗？',
          rtext:'可以的。外国人可以跟中国自然人或中国企业合资开办公司，也可以单独自己在中国投资开办公司。外国人在中国开办公司需要提供个人护照扫描件或护照颁发所在国家的中国领事馆/大使馆出具的个人公证书，证明该人员符合在中国境内设立公司的条件。<br/>外国企业在中国投资开办公司需要提供公司注册所在地区出具的公司公证书。<br/>中国境内自然人提供身份证，中国内资公司提供营业执照即可。<br/>提供以上资料和广州境内的注册地址材料即可受理'
        },
        {
          id:8,
          name:9,
          texttitle:'港澳台人士在境内设立企业会不会很麻烦？',
          rtext:'不麻烦。港澳台人事可以凭港澳通行证或台湾通行证办理企业设立。港澳台公司也可以在境内投资设立企业，提供当地出具的公司公证书，加上广州境内的注册地址材料即可受理。政府欢迎港澳台人士在境内进行投资，现在是大趋势，程序已经逐步简化。'
        },
        {
          id:9,
          name:10,
          texttitle:'我想做股权变更，有什么需要注意的地方？',
          rtext:'如果您公司主体类型是有限公司，从2021年5月1日开始，税务局规定自然人作为转让方转让股权，需要根据公司的所有者权益中盈利金额为基准，按股权比例计算出缴纳个人所得税和印花税。缴纳费用后税局会出具一份完税凭证给到市场监督局受理股权变更业务；如果转让方是公司则不需要先缴纳个人所得税和印花税。公司主体为股份有限公司、合伙企业、个人独资企业则不适用于这个政策。<br/>如果您公司主体类型是股份有限公司，对于公开募集的股权，股东转让其股份，应当在依法设立的证券交易场所进行或者按照国务院规定的其他方式进行；对于发起人股份的转让，须在公司设立登记三年后进行，并经公司原审批机关批准。<br/>如果您公司主体类型是合伙企业，合伙人可以向市场监督管理局申请退股或加入新的合伙人，个人独资企业的投资人可以做股权转让。两种主体类型办理股权转让都不需要出具完税凭证，但是按税局规定需要按个体生产经营收入新项目缴纳所得税。<br/>如果您公司主体类型是个体户，经营者不能做变更，可以申请把主体类型转变为有限公司，变更股东；也可以先注销再重新新办其他商事主体类型。'
        },
      ],
      NeedBoxModelbox:[
        {
          id:0,
          title:'银行开户',
          texttitle:'银行开户',
          litext:'什么是基本户？<br/>基本户是企业的主要的对公账户，主要用来缴纳税金、收付货款、发放工资等用途<br/><br/>怎样选择银行？<br/>每个银行的收费和资金使用灵活度不一样，开户前需要和银行沟通清楚基本情况',  
        },
        {
          id:1,
          title:'代理记账',
          texttitle:'代理记账',
          litext:'公司成立后要及时进行税务报到<br/>公司成立后，一般要求10个工作日内去所属税务机关报到，超时未报到的企业有可能会被罚款。<br/><br/>成立后第一个月起，需要会计每月记账报税。<br/>公司成立后第一个月起，税务机关就会要求公司每月进行记账报税，没有收入支出也需要报税。',  
        },
        {
          id:2,
          title:'商标注册',
          texttitle:'商标注册',
          litext:'注册商标的意义<br/>尽早将品牌注册为商标，能够帮助创业者保护品牌和规避商标法律风险。<br/><br/>注册商标注意事项：<br/>商标注册需先由商标顾问制定商标注册方案，预估商标注册风险及保护类别。',  
        },
        {
          id:3,
          title:'社保开户',
          texttitle:'社保开户',
          litext:'开设企业社保账户，为员工缴纳五险一金<br/>《社会保险法》规定，用人单位应当自成立之日起三十日内申请办理社会保险登记。<br/><br/>未能及时缴纳的，由征收机构责令自欠缴之日起向用人单位按日加收万分之五的滞纳金。<br/>一般建议公司自行办理社保开户，或委托服务商代办社保开户。 ',  
        },
      ]
    };
  },
  methods:{
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  }
};
</script>
<style lang="scss" scoped>
.Fybox{
  .Fytitle{
    text-align: center;
    color: #262626;
    padding: 20px 0;
    font-size: 24px;
    i{
      font-weight: 700;
    }
  }
  .Fyimg{
    background-size: contain;
  }
  .Fywenzi{
    padding: 20px 25px;
    box-sizing: border-box;
    color: #fff;
    background-color: #cf1c1c;
    line-height: 24px;
    span{
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.Service{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .ServiceTitle{
    text-align: center;
    color: #262626;
    padding: 20px 0;
    font-size: 24px;
    i{
      font-weight: 700;
    }
  }
  .ServiceBox{
    ::v-deep .van-grid-item__content{
      background-color: transparent;
      padding: 10px 5px;
    }
  }
  
}
.NeedBox{
  background-color: #cf1c1c;
  .NeedBoxTitle{
    color: #fff;
    font-size: 24px;
    padding: 20px 0;
    i{
      display: block;
      margin: 0 auto;
      font-weight: 100;
      text-align: center;
    }
    span{
      display: block;
      margin: 5px auto;
      font-weight: 700;
      text-align: center;
    }
  }
  .NeedBoxModelbox{
    .van-tabs{
      background-color: #cf1c1c;
      ::v-deep .van-tabs__nav{
        background-color: #cf1c1c;
      }
      ::v-deep .van-tab{
        background-color: #cf1c1c;
        color: #fff;
        height: 30px;
        padding: 0 5px;
      }
      ::v-deep .van-tab--active{
        background-color: #fff;
        border-radius: 25px;
        height: 30px;
      }
    }
    .TABactiveShow{
      width: 94%;
      margin: 20px auto;
      .title{
        display: block;
        color:#fff;
        text-align: center;
        margin-bottom: 10px;
      }
      .l_center{
        font-size: 12px;
        color: #fff;
        line-height: 22px;
      }
    }
  }
}
.Problem{
  padding: 20px 0;
  background:url(../img/newhome/TLBG.png) left top repeat-y ;
  background-size: contain;
  .Ptitle{
    color: #fff;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 100;
    span{
      font-weight: 700;
    }
  }
  ::v-deep .van-cell{
    background-color: transparent;
    color: #fff;
  }
  ::v-deep .van-collapse-item__content{
    color: #000;
  }
}
.LxListsClass{
 padding:  15px;
  background-color:#cf1c1c;
  ::v-deep .van-grid-item__content--center{
    background-color:#cf1c1c;
  }
  ::v-deep .van-grid-item:nth-child(1),::v-deep .van-grid-item:nth-child(2),::v-deep .van-grid-item:nth-child(4),::v-deep .van-grid-item:nth-child(5){
    border-right: 1px solid #fff;
  }
  ::v-deep .van-grid-item:nth-child(4),::v-deep .van-grid-item:nth-child(5),::v-deep .van-grid-item:nth-child(6){
    border-top: 1px solid #fff;
  }
}
.subject{
  background-color: #cf1c1c;
  padding: 20px 10px;
  .subject_title{
    color: #fff;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    span{
      font-weight: 700;
    }
  }
  .TABbottom{
    border-top: 1px solid #fff;
    margin-top: 15px;
    padding-top: 15px;
    p{
      color: #fff;
      line-height: 16px;
      font-size: 12px;
    }
    .TABbtn{
      display: flex;
      justify-content: space-around;
      margin: 10px 0;
      span{
        padding: 5px 10px;
        background-color: #fff;
        border-radius: 5px;
       
      }
    }
  }
  .TABactiveShow{
  
    .title{
      display: block;
      color:#fff;
      text-align: center;
    }
    .l_box{
      display: inline-block;
      font-style: normal;
      border: 1px solid #fff;
      border-radius: 25px;
      padding: 2px 10px;
      color: #fff;
      margin: 10px 0;
      font-size: 12px;
    }
    .l_center{
      font-size: 12px;
      color: #fff;
      line-height: 16px;
    }
    .b_box{
      display: inline-block;
      font-style: normal;
      border: 1px solid #fff;
      border-radius: 25px;
      padding: 2px 10px;
      color: #fff;
      margin: 10px 0;
      font-size: 12px;
    }
    .b_center{
      font-size: 12px;
      color: #fff;
      line-height: 16px;
    }
  }
  
  .van-tabs{
    background-color: #cf1c1c;
    ::v-deep .van-tabs__nav{
      background-color: #cf1c1c;
    }
    ::v-deep .van-tab{
      background-color: #cf1c1c;
      color: #fff;
      height: 30px;
      padding: 0 5px;
    }
     ::v-deep .van-tab--active{
      background-color: #fff;
      border-radius: 25px;
      height: 30px;
     }
  }
}
</style>