<template>
    <div>
        <div class="his-hot">
            <div class="hd">
                <span>历史记录</span>
                <van-icon name="delete-o" />
            </div>
            <div class="bd">
                <van-tag plain type="primary" v-for="(item,index) in HistoryHotListData" :key="index">{{item}}</van-tag>
                
            </div>
        </div>
        <div class="his-hot">
            <div class="hd">
                <span>热门搜索</span>
               
            </div>
            <div class="bd">
                <van-tag plain type="primary" v-for="(item,index) in hotKeywordListData" :key="index" :class="item.is_hot == 1?'cur':''">{{item.keyword}}</van-tag>
               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: ["HistoryHotListData" , "hotKeywordListData"],
    methods:{

    }
}
</script>
<style lang='scss' scoped>
    .his-hot{
        background-color: #fff;
         margin-bottom: 0.2rem;
        .hd {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            padding: 0.10rem 0.08rem 0;
           
            span{
                font-size: 0.16rem;
                color: #000;
                font-weight: 600;
            }
            .van-icon{
                font-size: 0.24rem;
               
            }
        }
        .bd{
            padding: 0.10rem 0.08rem 0.1rem;
            .van-tag{
                color: #ccc;
                margin-right: 0.05rem;
                margin-top: 0.05rem;
                padding: 0.02rem 0.04rem;
            }
            .cur{
                color: red ;
            }
        }
    }
</style>