<template>
    <div class="Wsxkz">
      <WSXKZ />
    </div>
  </template>
  <script>
  import WSXKZ from "@/components/WSXKZ.vue";
  export default {
    components: { WSXKZ },
    data() {
      return {};
    },
  
    methods: {},
  };
  </script>
  <style lang="scss" scoped>
  .Wsxkz {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #efefef;
    overflow-y: scroll;
  }
  </style>